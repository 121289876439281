<template>
    <div class="animated fadeIn">
        <LoadingWidget v-if="initStatus != 1" :status="initStatus" @init="init" />
        <div v-else>
            <b-card>
                <template v-slot:header>
                    <h5>Manage Cabang</h5>
                </template>
                <div class="row">
                    <div class="col-lg-4">
                        <b-form-group label="List User">
                            <multiselect @search-change="onSearchUser" @select="getCabangByUser($event)" v-model="uservalue"
                                :options="users" :multiple="false" :close-on-select="true" :clear-on-select="false"
                                :preserve-search="true" placeholder="Cari User (min 3 karakter)" label="name"
                                track-by="name" :preselect-first="true">
                            </multiselect>
                        </b-form-group>
                    </div>
                    <div class="col-lg-8">
                        <b-form-group label="List Cabang">
                            <multiselect @remove="removeCabangSelected" @select="cabangSelected($event)" v-model="value"
                                :options="cabang" :multiple="true" :close-on-select="false" :clear-on-select="false"
                                :preserve-search="true" placeholder="Select Cabang" label="name" track-by="name"
                                :preselect-first="true" name="cabang_id[]">
                            </multiselect>
                        </b-form-group>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-12">
                        <b-table v-if="value.length" striped hover :items="value" :fields="fields"></b-table>
                        <b-form @submit.prevent="onConfirmEditForm">
                            <div class="d-none">
                                <input hidden type="text" v-model="uservalue.id" name="user_staff_id">
                                <input hidden v-for="v in value" v-model="v.id" type="text" name="cabang_id[]">
                            </div>
                            <div class="d-flex justify-content-end">
                                <button class="btn btn-primary">Update</button>
                            </div>
                        </b-form>
                    </div>
                </div>
            </b-card>
        </div>
    </div>
</template>

<script>
import { validationMixin } from "/node_modules/vuelidate";
import { required } from "/node_modules/vuelidate/lib/validators";

export default {
    mixins: [validationMixin],
    data: () => {
        return {
            initStatus: 0,
            initStatusDetail: 0,
            isBusy: true,
            form: {
                user_staff_id: null,
                cabang_id: [],
            },
            props: {
                value: {
                    type: [String, Number],
                    default: "",
                },
            },
            fields: [
                {
                    key: "kode",
                    label: "Kode",
                    thClass: "nameOfTheClassTh",
                    tdClass: "nameOfTheClassTd",
                },
                {
                    key: "name",
                    label: "Nama",
                    thClass: "nameOfTheClassTh",
                    tdClass: "nameOfTheClassTd",
                },
                {
                    key: "alamat",
                    label: "Alamat",
                    thClass: "nameOfTheClassTh",
                    tdClass: "nameOfTheClassTd",
                },
            ],
            value: [],
            cabang: [],
            cabangusers: [],
            uservalue: [],
            userforupdate: null,
            users: [],
            currentPage: 1,
            perPage: null,
            rows: null,
            totalViewText: "",
            page: "",
            size: "",
            from: "",
            limit: "",
            query: "",
        };
    },
    validations: {
        form: {
            cabang_id: { required },
            user_staff_id: { required },
        },
    },
    //----------------- Init Function -----------------//
    methods: {
        init: function () {
            this.initStatus = 0;
            const cabang = this.$axios
                .get("api/admin/cabang/all" + this.query)
                .then((response) => {
                    let datas = response.data.data;
                    this.cabang = datas;
                    this.toggleBusy();
                })
                .catch((error) => {
                    this.toggleBusy();
                });

            Promise.all([cabang])
                .then(() => {
                    this.initStatus = 1;
                    this.toggleBusy();
                })
                .catch((error) => {
                    this.initStatus = -1;
                    this.toggleBusy();
                });
        },
        toggleBusy() {
            if (this.isBusy == true) {
                this.isBusy = false;
            } else {
                this.isBusy = true;
            }
        },
        getCabangByUser(event) {
            // this.initStatus = 0
            this.$axios
                .post("api/admin/cabang-user/list", {
                    user: event.id
                })
                .then((response) => {
                    this.cabangusers = response.data;
                    this.value = []

                    let dataValue = [];
                    for (var data of response.data) {
                        dataValue.push({
                            alamat: data.cabang[0].alamat,
                            hp: data.cabang[0].hp,
                            id: data.cabang[0].id,
                            kode: data.cabang[0].kode,
                            name: data.cabang[0].name,
                        })
                    }
                    this.value = dataValue;
                    this.toggleBusy();
                    // this.initStatus = 1
                    this.form = {
                        'user_staff_id': this.uservalue.id,
                        'cabang_id': dataValue.map(item => item.id)
                    }
                })
                .catch((error) => {
                    this.toggleBusy();
                    // this.initStatus = 1
                });
        },

        onSearchUser(value) {
            if (value.length > 2) {
                this.$axios
                    .get(`api/admin/management-users/all?search=${value}` + this.query)
                    .then((response) => {
                        let datas = response.data.data;
                        this.users = datas;
                        this.toggleBusy();
                    })
                    .catch((error) => {
                        this.toggleBusy();
                    });
            }
        },

        cabangSelected(data) {
            let cabang_id = this.value.map(i => i.id)
            this.form = {
                'user_staff_id': this.uservalue.id,
                'cabang_id': [...cabang_id, data.id]
            }
        },
        removeCabangSelected(data) {
            let cabang_id = this.value.map(i => i.id)
            this.form = {
                'user_staff_id': this.uservalue.id,
                'cabang_id': cabang_id.filter(i => i !== data.id)
            }
        },

        //----------------- Confirm Edit Form Function -----------------//
        onConfirmEditForm: function () {
            this.$v.form.$touch();
            if (this.$v.form.$invalid) {
                return
            } else {
                this.showSweetAlertEditForm();
            }
        },

        //----------------- Update Form Function -----------------//
        showSweetAlertEditForm: function () {
            const Toast = this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                },
            });

            this.$swal({
                html: "Anda yakin memetakan ulang user cabang?",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#1e73be",
                cancelButtonText: "Batal",
                confirmButtonText: "Konfirmasi",
                closeOnConfirm: false,
                closeOnCancel: false,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.loadingMain = true;
                    this.$swal({
                        title: "Mohon Menunggu",
                        html: "Data sedang diproses...",
                        allowOutsideClick: false,
                        showConfirmButton: false,
                        willOpen: () => {
                            this.$swal.showLoading();
                        },
                    });
                    this.$axios
                        .post("/api/admin/cabang-user/update", this.form)
                        .then((response) => {
                            if (response.status == 200) {
                                this.loadingMain = false;
                                Toast.fire({
                                    iconHtml: `
                                        <div class="m-2">
                                            <i class="fa fa-check"></i>
                                        </div>
                                        `,
                                    iconColor: '#a5dc86',
                                    html: "Selamat! Peubahan data telah tersimpan",
                                });
                            }
                        })

                        .catch((error) => {
                            console.log(error);
                            this.loadingMain = false;
                            Toast.fire({
                                title: "Gagal",
                                icon: "error",
                                html: "Peubahan data gagal disimpan",
                            });
                        });
                }
            });
        },

    },
    created: function () {
        this.init();
    },
}
</script>


<style lang="scss">
.required label::after {
    content: "*";
    color: #f79483;
}

::-webkit-scrollbar {
    -webkit-appearance: none;
    height: 5px;
    width: 5px;
}

::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.my-multiselect {
    display: inline-block;
    vertical-align: middle;
}

.multiselect__tag {
    background: #1e73be;
    color: #fff;
}

.multiselect__tag-icon::after {
    color: #fff;
}

.multiselect__option--highlight {
    background: #1e73be;
    outline: none;
    color: #fff;
}
</style>